<template>
    <div class="ui-page">
        <van-form @submit="onSubmit" autocomplete="off">
            <div style="padding: 0.16rem;">
                <van-radio-group v-model="dataForm.type" direction="horizontal">
                    <van-radio :name="1">会员</van-radio>
                    <van-radio :name="2">员工</van-radio>
                </van-radio-group>
            </div>
            <van-field
                v-model="dataForm.username"
                name="用户名"
                label="用户名"
                placeholder="用户名"
                :rules="[{ required: false, message: '请填写用户名' }]"
            />
            <van-field
                v-model="dataForm.password"
                type="password"
                name="密码"
                label="密码"
                placeholder="密码"
                :rules="[{ required: false, message: '请填写密码' }]"
            />
            <div style="margin: 16px;">
                <van-button round block :loading="saving" type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            saving: false,
            dataForm: {
                type: 1,
                username: '',
                password: ''
            }
        }
    },
    methods: {
        onSubmit() {
            this.saving = true
            this.$axios.post('/api/wechatOfficial/v1/auth/login', {
                type: this.dataForm.type,
                username: this.dataForm.username,
                password: this.dataForm.password
            }).then(res => {
                this.$store.commit('login', {
                    userId: res.data.userId,
                    userType: res.data.userType,
                    token: res.data.token,
                    refreshToken: res.data.refreshToken,
                    userMode: 2,
                    expireTime: res.data.expire * 1000 + new Date().getTime()
                })
                if (this.dataForm.type === 2) {
                    this.$router.replace('/hotel-list')
                } else {
                    this.$router.replace('/my-rooms')
                }
            }).catch(err => {
                this.saving = false
                this.$dialog.alert({
                    message: err.message,
                })
            })

        }
    }
}
</script>

<style scoped>

</style>